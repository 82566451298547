.nav {
  display: flex;
  border-bottom: 1px solid $nav-border;
  background-color: #fff;
  flex-wrap: wrap;
}

@media (min-width: $nav-breakpoint) {
  .nav {
    flex-wrap: nowrap;
  }
}

/* Logo in navigation */

.logo {
  flex: 0 0 220px;
  position: relative;
}

.logo_link {
  display: block;
}

.logo_img {
  width: 90%;
  padding: 10px 30px;
}

@media (min-width: $nav-breakpoint) {
  .logo {
    flex-basis: 260px;
  }
}

/* menu toggle button */

.menu_toggle {
  justify-content: flex-end;
  flex: 1;
  display: flex;
}

.menu_toggle_button {
  align-self: center;
  color: $link-color;
  padding: 1em 2em;
  font-size: 0.8em;
  font-weight: 600;
  cursor: pointer;
}

@media (min-width: $nav-breakpoint) {
  .menu_toggle {
    display: none;
  }
}

/* navigation list */

.nav_list {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  flex-direction: column;
  transform-origin: top center;
  margin-top: 0;
  max-height: 0;
  overflow: hidden;
  transition: transform 120ms, max-height 120ms;
}

.nav_list.nav_open {
  max-height: 100%;
}

.nav_item {
  flex: 1;
  text-align: center;
  align-self: center;
}

.nav_link {
  text-decoration: none;
  color: $link-color;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 600;
  padding: 0.4em;
}

@media (min-width: $nav-breakpoint) {
  .nav_list {
    flex-direction: row;
    height: auto;
    max-height: 100%;
  }
}
