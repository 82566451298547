@use "sass:color";

.projects-group,
.press-list {
  margin-top: 1.5em;
  margin-bottom: 3em;
  grid-template-columns: 1fr;
  grid-gap: 2em;

  @media (min-width: $screen-sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $screen-md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $screen-lg) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.projects-item,
.press-item {
  transition: flex-basis 180ms ease-out;
  position: relative;
  max-height: 250px;

  & > a {
    display: block;
    background-size: cover;
    background-position: center;
    margin: auto;
    line-height: 0;
    padding-bottom: 60%;
    font-weight: 600;
  }
}

.projects-item a::after,
.press-item a::after {
  content: attr(data-title);
  color: $black;
  position: absolute;
  padding: 0.5em;
  background-color: color.adjust($white, $alpha: -0.2);
  line-height: 1;
}

.projects-item a::before,
.press-item a::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 1px;
  background-color: transparent;
  transition: background-color 75ms;
}

.projects-item a:hover::before,
.press-item a:hover::before {
  background-color: rgba(0 0 0 / 20%);
}

.project-item-meta,
.press-item-meta {
  color: $secondary-color;
  mix-blend-mode: color-dodge;
  position: absolute;
  bottom: 0;
  padding: 1em;
  background-color: rgba(0 0 0 / 60%);
  margin: 1px;
  left: 0;
  right: 0;
  line-height: 1;
}

.project-item-meta-small,
.press-item-meta-small {
  font-size: 12px;
  display: block;
  margin-top: 0.5em;
}
