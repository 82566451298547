@use "sass:color";

h1 {
  font-size: 2em;
  color: $header-color;
}

h2 {
  font-size: 1.2em;
  font-weight: 600;
}

h3 {
  font-size: 1.1em;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
address {
  line-height: 1.5;
  margin-top: 1.5em;

  &:first-child {
    margin-top: 0;
  }
}

em {
  font-weight: 600;
}

a {
  color: $text-color;
  text-decoration: underline;
  transition: color 75ms ease-out;

  &:hover {
    color: color.adjust($text-color, $lightness: -15%);
  }
}
