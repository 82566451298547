@use "sass:color";

.hero-content h1 {
  text-align: center;
  line-height: 1.1;

  @media (min-width: $screen-sm) {
    font-size: 2.5em;

    &::after {
      content: "";
      display: inline-block;
      height: 4px;
      background-color: $button-color;
      width: 25%;
      border-radius: 2px;
    }
  }
}

.hero-content p {
  text-align: center;
  line-height: 1.1;
  color: $header-color;
}

.hero-button {
  display: inline-block;
  margin-top: 2em;
  padding: 0.5em 1.5em;
  background-color: $button-color;
  border-radius: 5px;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  transition: background-color 120ms ease-out;

  &:hover {
    color: #fff;
    background-color: color.adjust($button-color, $lightness: -5%);
  }
}
